<template>
  <div class="component-wrap">
    <div v-if="!noticeShow">
      <div class="component-tit">
        {{ $t("demand.call_for_infor") }}
        <!-- 征集信息 -->
      </div>
      <a-spin :spinning="noticeLoading">
        <!-- 状态 -->
        <div class="filter-wrap" id="pageTop">
          <ListFilter
            only
            :name="$t('CM_Status')"
            :allObj="{
              name: $t('CM_All'),
              id: 1,
            }"
            :dataSource="complete"
            @onChange="switchComplete"
          >
            <template v-slot:right>
              <div class="search">
                <a-input-search
                  v-model:value="queryParam.taskName"
                  :placeholder="
                    $t('demand.please_enter_the_name_of_the_demand_collection')
                  "
                  style="width: 220px"
                  enter-button
                  allow-clear
                  @search="handleSearch(queryParam)"
                />
                <!-- 请输入需求征集名称 -->
              </div>
            </template>
          </ListFilter>
        </div>

        <div class="table-wrap">
          <a-table
            :columns="columns"
            :data-source="state.dataSource"
            :rowKey="(record, index) => index"
            :bordered="false"
            :pagination="pagination"
            :loading="state.loading"
            @change="handleTableChange"
          >
            <template #taskName="{ text }">
              <a-tooltip>
                <template #title>{{ text }}</template>
                {{ text }}
              </a-tooltip>
            </template>
            <template #times="{ record }">
              {{ dateFormat(record.startTime, "YYYY-MM-DD") }} ~
              {{ dateFormat(record.endTime, "YYYY-MM-DD") }}
            </template>
            <template #complete="{ text }">
              <span v-if="text == 1">{{ $t("CM_Completed") }}</span>
              <!-- 已完成 -->
              <span v-else-if="text == 3">{{ $t("overdue") }}</span>
              <!-- 逾期 -->
              <span v-else-if="text == 2">{{ $t("XB_UnderWay") }}</span>
              <!-- 进行中 -->
            </template>
            <template #action="{ record }">
              <div class="action">
                <a-button type="link" @click="getNotice(record.taskId)">{{
                  $t("cm_view")
                }}</a-button>
                <!-- 查看 -->
                <a-button type="link" @click="add(record)">{{
                  $t("cm_add")
                }}</a-button>
                <!-- 新增 -->
              </div>
            </template>
          </a-table>
        </div>
      </a-spin>
    </div>

    <div class="notice-info" v-if="noticeShow">
      <div class="filters">
        <div class="component-tit">{{ $t("demand.call_for_infor") }}</div>
        <!-- 征集信息 -->
        <a-button type="primary" class="back" @click="noticeShow = false">
          {{ $t("go_back") }}
          <!-- 返回 -->
        </a-button>
      </div>
      <div class="notice">
        <div class="row">
          <div class="l">{{ $t("demand.table.solicitation_year") }}</div>
          <!-- 征集年度 -->
          <div class="r">{{ noticeActive.taskYear }}</div>
        </div>
        <div class="row">
          <div class="l">{{ $t("demand.table.solicitation_name") }}</div>
          <!-- 征集名称 -->
          <div class="r">{{ noticeActive.taskName }}</div>
        </div>
        <div class="row">
          <div class="l">{{ $t("demand.table.collection_time") }}</div>
          <!-- 征集时间 -->
          <div class="r">
            {{ dateFormat(noticeActive.startTime) }} ~
            {{ dateFormat(noticeActive.endTime) }}
          </div>
        </div>
        <div class="row">
          <div class="l">{{ $t("demand.call_for_requests") }}</div>
          <!-- 征集要求 -->
          <div class="r vhtml" v-html="noticeActive.intro"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, toRaw } from "vue";
import { useRoute } from "vue-router";
import { useFetchData } from "@/utils/useFetchData";
import { collectList, collectDetail } from "@/api/demand";
import { dateFormat } from "@/utils/tools";
import ListFilter from "@/components/new/filter/ListFilter.vue";
export default {
  components: {
    ListFilter,
  },
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const route = useRoute();
    const stateData = reactive({
      complete: [
        { id: 3, name: $t("overdue") }, // 逾期
        { id: 2, name: $t("XB_UnderWay") }, // 进行中
      ],
      columns: [
        {
          title: $t("demand.table.solicitation_name"),
          // title: "需求征集名称",
          dataIndex: "taskName",
          width: 250,
          ellipsis: true,
          slots: { customRender: "taskName" },
        },
        {
          title: $t("demand.table.solicitation_year"),
          // title: "征集年度",
          dataIndex: "taskYear",
          width: 100,
          ellipsis: true,
        },
        {
          title: $t("demand.table.collection_time"),
          // title: "征集时间",
          width: 250,
          slots: { customRender: "times" },
        },
        {
          title: $t("demand.table.status"),
          // title: "状态",
          dataIndex: "complete",
          width: 100,
          slots: { customRender: "complete" },
        },
        {
          title: $t("demand.table.operate"),
          // title: "操作",
          dataIndex: "action",
          width: 130,
          slots: { customRender: "action" },
        },
      ],
      noticeActive: null,
      noticeShow: false,
      noticeLoading: false,
    });

    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return collectList(form).then((res) => {
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };

    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 10,
      tableSize: "middle", // 'default' | 'middle' | 'small'
      stripe: false,
    });

    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });

      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["10", "30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    let queryParam = reactive({
      taskName: "",
      complete: 1,
    });

    const handleSearch = (searchModel) => {
      if (searchModel) {
        queryParam = searchModel;
      } else {
        queryParam.complete = 1;
        queryParam.taskName = "";
      }
      setPageInfo({ current: 1, ...toRaw(queryParam) });
      reload();
    };

    const switchComplete = (arr) => {
      queryParam.complete = arr[0];
      handleSearch(queryParam);
    };

    const getNotice = (taskId) => {
      stateData.noticeLoading = true;
      collectDetail(taskId).then((res) => {
        if (res.ret == 0) {
          stateData.noticeActive = res.data;
          stateData.noticeShow = true;
        }
        stateData.noticeLoading = false;
      });
    };
    if (route.query.taskType == 13 && route.query.taskId) {
      getNotice(Number(route.query.taskId));
    }

    const add = (data) => {
      emit("changeKey", {
        key: "add",
        data,
      });
    };

    return {
      dateFormat,
      ...toRefs(stateData),
      state,
      pagination,
      handleTableChange,
      queryParam,
      handleSearch,
      switchComplete,
      getNotice,
      add,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  .component-tit {
    font-size: 20px;
    line-height: 26px;
    color: #333;
    font-weight: 600;
    padding: 16px 0 12px;
  }

  .filter-wrap {
    padding-bottom: 12px;
    margin-bottom: 16px;

    .filter {
      padding: 5px 0;
    }

    .search {
      padding: 5px 0;
      .mixinFlex(flex-end);

      ::v-deep(.ant-input-search) {
        padding-right: 1px;
        border-radius: 4px 0 0 4px;
      }

      ::v-deep(.ant-btn) {
        border-radius: 0 4px 4px 0;
      }
    }
  }
  .table-wrap {
    ::v-deep(.ant-table-pagination.ant-pagination) {
      float: none;
      text-align: center;
    }
    .action {
      ::v-deep(.ant-btn) {
        padding-right: 4px;
        padding-left: 0;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .notice-info {
    min-height: 100%;
    .filters {
      .mixinFlex(space-between; center);
    }
    .back {
      border-radius: 4px;
    }
    .notice {
      margin-top: 24px;
      .row {
        margin-bottom: 16px;
        .mixinFlex(space-between);
        .l {
          font-size: 16px;
          line-height: 1.5;
          width: 160px;
          color: #666;
        }
        .r {
          font-size: 16px;
          line-height: 1.5;
          width: calc(100% - 80px);
        }
      }
    }
  }
}
</style>
