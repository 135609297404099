import request from '@/utils/request';

// 征集列表
export const collectList = params => request.post('study/demand/task/list', params)

// 征集详情
export const collectDetail = id => request.get(`study/demand/task/${id}/detail`)

// 需求目录
export const demandFolder = () => request.get(`study/demand/folder/tree`)

// 需求保存
export const demandSave = params => request.post(`study/demand/submit`, params)

// 需求保存记录
export const demandSaveList = params => request.post(`study/demand/list`, params)

// 需求保存记录删除
export const demandDel = ids => request.post(`study/demand/study/del`, { ids })

// 需求提交
export const demandReport = ids => request.post(`study/demand/report`, { ids })